<template>
  <div id="test15">
    <div class="bigdiv">
      <div style="position: absolute; right: 15px; top: 15px; z-index: 9999999">
        <div>
          <el-button
            type="primary"
            @click="selectDrawFenceTypeShowToggle(true)"
            v-if="!drawFenceStatu"
            >新增围栏</el-button
          >
          <el-button type="primary" @click="endDrawFence" v-else
            >取消新增</el-button
          >
        </div>
        <div style="margin-top: 10px" v-if="drawFenceStatu">
          <el-button type="primary" @click="operateFencefn">保存围栏</el-button>
        </div>
        <div style="margin-top: 10px">
          <el-button type="primary" @click="lineShowToggle">
            {{ lineShow ? "关闭路线" : "显示路线" }}
          </el-button>
        </div>
      </div>
      <div id="index_sheet_left">
        <!--<div
          style="
            padding: 15px 20px;
            text-align: center;
            border-bottom: 1px solid #ebeef5;
          "
        >
          <span>车辆分类</span>
        </div>
         <div
          style="
            height: 13.6vh;
            padding: 10px 100px;
            border-bottom: 1px solid #ebeef5;
            display: flex;
            align-items: center;
          "
        >
          <div>
            <el-checkbox-group
              v-model="checkedCities"
              @change="handleCheckedCitiesChange"
            >
              <div
                style="margin-bottom: 5px"
                v-for="city in cities"
                :key="city"
              >
                <el-checkbox :label="city">{{ city }}</el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
        </div> -->
        <div style="padding: 10px 20px; display: flex">
          <el-input v-model="serchKey" placeholder="请输入车牌"></el-input>
          <el-button type="primary" @click="serchCardEvent">搜索</el-button>
        </div>
        <!-- <div style="padding: 10px 20px">
          <el-select v-model="value" clearable placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div> -->
        <div style="padding: 10px 20px">
          <el-radio-group v-model="radio2" @input="carStatuChange">
            <el-radio-button label="全部"></el-radio-button>
            <el-radio-button label="在线"></el-radio-button>
            <el-radio-button label="离线"></el-radio-button>
          </el-radio-group>
        </div>
        <div style="padding: 0px 20px; height: 557px">
          <div
            :class="i == index ? 'item1' : 'item'"
            v-for="(item, index) in carLsitShow"
            :key="index"
            @click="itemClick(index)"
          >
            <div style="display: flex; align-items: center">
              <div
                style="
                  height: 100%;
                  width: 20px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <div
                  :class="item.accStatus == 1 ? 'item-spot1' : 'item-spot'"
                ></div>
              </div>
              <div>
                {{ item.carNum }}
              </div>
            </div>
            <div class="item-caozuo" @click.stop="itemClick(index, true)">
              操作>
            </div>
          </div>
        </div>
      </div>
      <div class="containerdiv">
        <div id="container"></div>

        <div
          :class="
            CarInfoShow ? 'bottomdiv bottomdivShow' : 'bottomdiv bottomdivNone'
          "
        >
          <div class="bottomdiv-title">
            <span
              >[{{ CarInfo.isConn == 1 ? "在线" : "离线" }}] ·
              {{ CarInfo.carNum }}({{ CarInfo.groupName }})</span
            >
          </div>
          <div style="padding: 15px 24px; height: 78px; display: flex">
            <div style="font-size: 14px; flex: 1; margin-left: 50px">
              <div class="bottomdiv-one">
                <span>速度:{{ CarInfo.speed }}km/h</span>
                <span v-show="CarInfo.dir < 90">方向:东北</span>
                <span v-show="CarInfo.dir > 90 && CarInfo.dir < 180"
                  >方向:东南</span
                >
                <span v-show="CarInfo.dir > 180 && CarInfo.dir < 270"
                  >方向:西南</span
                >
                <span v-show="CarInfo.dir > 270 && CarInfo.dir < 360"
                  >方向:西北</span
                >
                <span>Acc:{{ CarInfo.accStatus == 1 ? "点火" : "熄火" }}</span
                ><span>电源:电源正常</span>
              </div>
              <div class="bottomdiv-one">
                <span
                  >通讯:{{ time.getFullYear() }}-{{ time.getMonth() + 1 }}-{{
                    time.getDate()
                  }}
                  {{ time.getHours() }}:{{ time.getMinutes() }}:{{
                    time.getSeconds()
                  }}</span
                >
                <span>今日里程: {{ CarInfo.todayKm }} KM</span>
                <span>怠速: {{ CarInfo.stopTime || "0分钟" }} </span>
                <span>今日桶数: {{ tongshu }} 桶 </span>
              </div>
              <div>
                <span>地址:{{ address }}</span>
              </div>
            </div>
            <div style="width: 350px; margin-right: 50px">
              <div style="margin-bottom: 10px">
                <el-button type="primary" size="small" @click="toCheckTrack"
                  >查询</el-button
                >
                <!-- <el-button type="primary" size="small" @click="toPathPlanning">指令</el-button> -->
                <el-button type="primary" size="small" @click="tofencelist"
                  >设置</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="新增围栏"
      :visible.sync="selectDrawFenceTypeShow"
      width="30%"
    >
      <el-select v-model="currentFenceType" placeholder="请选择">
        <el-option label="圆形围栏" :value="1"> </el-option>
        <el-option label="多边形围栏" :value="2"> </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="selectDrawFenceTypeShow(false)">取 消</el-button>
        <el-button type="primary" @click="startDrawFence()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import { proxyRequest, operateFence, fenceList } from "@/request/api";
import carred from "../assets/carred.png";
import cargrey from "../assets/cargrey.png";
import cargreen from "../assets/cargreen.png";
import { color } from "echarts";
window._AMapSecurityConfig = {
  securityJsCode: "380be16e67ca78c3725692b21427d46d", // 开发者密钥
};
const cityOptions = ["环卫车", "三轮车"];
export default {
  name: "DemoTest10",
  props: {
    name: {
      type: String,
      default: function () {
        return "";
      },
    },
    isEdit: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    areas: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      drawFenceStatu: false, // 编辑围栏
      selectDrawFenceTypeShow: false, // 选择围栏类型弹窗
      currentFenceType: 1, // 围栏形状 1 圆形 2 多边形
      polygonPaths: [],
      map: null,
      checkAll: true,
      checkedCities: ["环卫车", "三轮车"], // '洒水车',
      cities: cityOptions,
      isIndeterminate: true,
      serchKey: "",
      options: [
        {
          value: "选项1",
          label: "里程排序",
        },
        {
          value: "选项2",
          label: "编号排序",
        },
        {
          value: "选项3",
          label: "车牌排序",
        },
        {
          value: "选项4",
          label: "ACC时长排序",
        },
        {
          value: "选项5",
          label: "速度排序",
        },
      ],
      value: "选项1",
      radio2: "全部",
      carLsit: [],
      carLsitShow: [],
      i: 0,
      CarInfo: {}, //车的详细信息
      CarInfoShow: false, //车的详细信息框是否显示
      address: "",
      tongshu: 0,
      polyEditor: null,
      circleEditor: null,
      polygons: [],

      polygon: null,
      fenceList: [],
      time: new Date(),
      polyline: [], //轨迹
      marker: {},
      lineShow: false,
    };
  },
  async mounted() {
    let token = sessionStorage.getItem("cartoken");
    let res = await proxyRequest({
      path: "Transfer/carHomeTF",
      data: JSON.stringify({
        // tfKey: "f35d31a6dad1eded2364682290a0d437",
      }),
      header: JSON.stringify({
        Authorization: token,
        tfKey: "f35d31a6dad1eded2364682290a0d437",
      }),
    });

    function getRandomColor() {
      let letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

    const _carList = res.results.map((carItem) => {
      return {
        ...carItem,
        linePath: [],
        lineColor: getRandomColor(),
      };
    });

    console.log("_carList_carList", _carList);

    const _Date = new Date();
    const _date = `${_Date.getFullYear()}-${_Date.getMonth() + 1}-${
      _Date.getDate() - 1
    }`;

    _carList.forEach(async (carItem) => {
      const _linePath = [];
      const car = await proxyRequest({
        path: "Transfer/gpsTrackListTF",
        data: JSON.stringify({
          vkey: carItem.vkey,
          gpsType: 0,
          isQueryByTime: 0,
          addDate: _date,
        }),
        header: JSON.stringify({
          Authorization: sessionStorage.getItem("cartoken"),
          tfKey: "f35d31a6dad1eded2364682290a0d437",
        }),
      });

      car.results.forEach((line) => {
        _linePath.push([line.lng, line.lat]);
      });

      carItem.linePath = _linePath;
    });

    this.carLsit = _carList;

    console.log("this.carLsit", this.carLsit);

    this.carLsitShow = _carList;

    this.initMap();

    setTimeout(() => {
      this.carLsit.forEach((car) => {
        // this.drawPolyline(car);
        this.startAnimation(car);
      });
    }, 1000);
  },
  methods: {
    async fenceListFn() {
      let res = await fenceList();
      this.fenceList = res.data.list;
    },

    async initMap() {
      await this.fenceListFn();
      AMapLoader.load({
        key: "b7c4488941d2e660802ee0ac4e010df2", //开发者Key
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.Polygon",
          "AMap.PolygonEditor",
          "AMap.Autocomplete",
          "AMap.PlaceSearch",
          "AMap.Scale",
          "AMap.OverView",
          "AMap.MoveAnimation",
          "AMap.ToolBar",
          "AMap.MapType",
          "AMap.PolyEditor",
          "AMap.CircleEditor",
          "AMap.Geolocation",
          "AMap.Geocoder",
          "AMap.AMapUI",
        ], // 需要使用的的插件列表
      }).then((AMap) => {
        this.map = new AMap.Map("container", {
          //设置地图容器id
          viewMode: "3D", //是否为3D地图模式
          zoom: 11, //初始化地图级别
          center: [111.30444444444444, 23.319166666666668], //初始化地图中心点位置
        });
        // 初始化多边形编辑
        this.initPoly();
        console.log(1);
        // 车辆点位
        this.carLsit.forEach((carItem) => {
          if (carItem.isConn == 1) {
            console.log(3);
            if (carItem.accStatus == 1) {
              let marker = new AMap.Marker({
                icon: new AMap.Icon({
                  image: cargreen,
                  imageSize: new AMap.Size(25, 53),
                }),
                position: [carItem.lng, carItem.lat],
                draggable: false,
                alt: carItem.deviceId,
                angle: carItem.dir,
                label: {
                  content: carItem.carNum,
                  offset: [-43, 30],
                },
              });
              this.marker = {
                ...this.marker,
                [carItem.vkey]: marker,
              };
              this.map.add(marker);
            } else {
              let marker = new AMap.Marker({
                icon: new AMap.Icon({
                  image: carred,
                  imageSize: new AMap.Size(25, 53),
                }),
                position: [carItem.lng, carItem.lat],
                draggable: false,
                alt: carItem.deviceId,
                angle: carItem.dir,
                label: {
                  content: carItem.carNum,
                  offset: [-43, 30],
                },
              });
              this.marker = {
                ...this.marker,
                [carItem.vkey]: marker,
              };
              this.map.add(marker);
            }
          } else {
            console.log(4);
            // 增加车辆点位
            let marker = new AMap.Marker({
              icon: new AMap.Icon({
                image: cargrey,
                imageSize: new AMap.Size(25, 53),
              }),
              position: [carItem.lng, carItem.lat],
              draggable: false,
              alt: carItem.deviceId,
              angle: carItem.dir,
              label: {
                content: carItem.carNum,
                offset: [-43, 30],
              },
            });
            this.marker = {
              ...this.marker,
              [carItem.vkey]: marker,
            };
            this.map.add(marker);
          }
        });

        console.log(2);
        // 增加围栏
        this.initFenceList();
      });
    },
    //绘制轨迹
    drawPolyline(car) {
      // this.polyline && this.map.remove(this.polyline);

      if (car == null) {
        this.polyline.forEach((item) => {
          this.map.remove(item);
        });

        this.polyline = [];
        return;
      }

      this.polyline.push(
        new AMap.Polyline({
          map: this.map,
          path: car.linePath,
          showDir: true,
          // strokeColor: "#28F", //线颜色
          strokeColor: car.lineColor,
          // strokeOpacity: 1,     //线透明度
          strokeWeight: 6, //线宽
          // strokeStyle: "solid"  //线样式
        })
      );

      // console.log("this.polyline", this.polyline);

      // this.map.setFitView();
    },
    //播放动画
    startAnimation(car) {
      // for (const key in this.marker) {
      //   this.marker[key].resumeMove();
      // }
      this.marker[car.vkey].moveAlong(car.linePath, {
        duration: 500, //可根据实际采集时间间隔设置
        // JSAPI2.0 是否延道路自动设置角度在 moveAlong 里设置
        autoRotation: true,
      });
    },
    //全选
    handleCheckAllChange(val) {
      this.checkedCities = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    //单选
    handleCheckedCitiesChange(value) {
      console.log("value--------->", value);
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },
    // 车辆在线全部更换
    carStatuChange(e) {
      if (e == "全部") {
        this.carLsitShow = this.carLsit;
        return;
      }

      if (e == "在线") {
        this.carLsitShow = this.carLsit.filter((car) => {
          return car.accStatus == 1;
        });
      } else {
        this.carLsitShow = this.carLsit.filter((car) => {
          return car.accStatus != 1;
        });
      }

      console.log("1111111111111", e);
    },

    itemClick(i, caozuo) {
      this.i = i;
      // this.map.setCenter([this.carLsit[i].lng, this.carLsit[i].lat], true);
      // this.map.setZoom(14, true);

      if (caozuo) {
        this.CarInfo = this.carLsit[i];
        this.CarInfoShow = true;
        AMap.plugin("AMap.Geocoder", () => {
          var geocoder = new AMap.Geocoder({
            // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
            city: "010",
          });
          var lnglat = [this.CarInfo.lng, this.CarInfo.lat];
          geocoder.getAddress(lnglat, (status, result) => {
            if (status === "complete" && result.info === "OK") {
              // result为对应的地理位置详细信息
              this.address = result.regeocode.formattedAddress;
            }
          });
        });
      } else {
        this.CarInfoShow = false;
      }
    },
    //轨迹查询
    toCheckTrack() {
      this.$router.push({
        path: "/CheckTrack",
        query: {
          vkey: this.carLsit[this.i].vkey,
          carNum: this.carLsit[this.i].carNum,
        },
      });
    },
    //路径规划
    toPathPlanning() {
      this.$router.push({
        path: "/PathPlanning",
        query: {
          lng: this.carLsit[this.i].lng,
          lat: this.carLsit[this.i].lat,
        },
      });
    },
    tofencelist() {
      this.$router.push({
        path: "/api/vihicle/getvihiclelist",
      });
    },
    selectDrawFenceTypeShowToggle(statu) {
      this.selectDrawFenceTypeShow = statu;
    },
    // 初始化围栏
    initFenceList() {
      console.log("初始化围栏----->", this.fenceList);

      this.fenceList.forEach((item) => {
        let _fence = null;

        if (
          Array.isArray(item.location_items) &&
          item.location_items.length > 0
        ) {
          //  多边形
          let path = [];
          item.location_items.forEach((i) => {
            path.push([i.longitude, i.latitude]);
          });
          console.log(path);
          _fence = new AMap.Polygon({
            path: path,
            strokeColor: "green",
            strokeWeight: 6,
            strokeOpacity: 0.2,
            fillOpacity: 0.4,
            fillColor: "#1791fc",
            zIndex: 50,
          });
        } else {
          console.log("圆形-------------", item);
          // 圆形
          _fence = new AMap.Circle({
            center: [item.longitude, item.latitude],
            radius: item.radius, //半径
            borderWeight: 3,
            strokeColor: "#FF33FF",
            strokeOpacity: 1,
            strokeWeight: 6,
            strokeOpacity: 0.2,
            fillOpacity: 0.4,
            strokeStyle: "dashed",
            strokeDasharray: [10, 10],
            // 线样式还支持 'dashed'
            fillColor: "#1791fc",
            zIndex: 50,
          });
        }

        _fence != null && this.map.add(_fence);
      });
    },

    // 车辆搜索
    serchCardEvent() {
      const keywords = this.serchKey.trim();
      if (keywords == "") {
        this.carLsitShow = this.carLsit;
        return;
      }

      this.carLsitShow = this.carLsit.filter((car) => {
        return car.carNum.includes(keywords);
      });
    },
    initPoly() {
      console.log("初始化编辑");
      this.polyEditor = new AMap.PolygonEditor(this.map);
      this.circleEditor = new AMap.CircleEditor(this.map);

      this.polyEditor.on("end", async (event) => {
        console.log("多边形结束回调");

        const date = new Date();
        const fence_name = `${date.getFullYear()}年${
          date.getMonth() + 1
        }月${date.getDate()}日 ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 添加`;

        const paths = [];
        event.target.getPath().forEach((pathItem) => {
          paths.push({
            latitude: pathItem.lat,
            longitude: pathItem.lng,
          });
        });
        console.log("paths", paths);
        try {
          const res = await operateFence({
            fence_name,
            location_json: JSON.stringify(paths),
            fence_type: 2,
          });

          if (res.code == 1 || res.msg == "请求成功") {
            this.$message.success("保存成功");
            this.reset();
          } else {
            this.$message.error("保存失败!");
          }

          console.log("res------------>", res);
        } catch (error) {
          this.$message.error("保存失败!");
        }
      });
      this.circleEditor.on("end", async (event) => {
        const date = new Date();
        const fence_name = `${date.getFullYear()}年${
          date.getMonth() + 1
        }月${date.getDate()}日 ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 添加`;

        console.log("半径---->");
        console.log("中心点---->", event.target.getCenter());

        try {
          const res = await operateFence({
            fence_name,
            radius: event.target.getRadius(),
            latitude: event.target.getCenter().lat,
            longitude: event.target.getCenter().lng,
            fence_type: 1,
          });

          if (res.code == 1 || res.msg == "请求成功") {
            this.$message.success("保存成功");
            this.reset();
          } else {
            this.$message.error("保存失败!");
          }

          console.log("res------------>", res);
        } catch (error) {
          this.$message.error("保存失败!");
        }
      });

      // if (this.polygons.length > 0) {
      //   this.polyEditor = new AMap.PolygonEditor(this.map, this.polygons[0]);
      // } else {
      //   this.polyEditor = new AMap.PolygonEditor(this.map);
      // }
      // // this.polyEditor.open()
      // let _this = this;
      // //关闭多边形编辑polygonEditor.close()触发该方法；
      // this.polyEditor.on("end", (event) => {
      //   // event.target 即为编辑后的多边形对象,event.target.getPath()得到编辑完成后的点数组
      //   let pointArr = event.target.getPath();
      //   this.polygonPaths = [];
      //   for (let i = 0; i < pointArr.length; i++) {
      //     this.polygonPaths.push({
      //       latitude: pointArr[i].lat,
      //       longitude: pointArr[i].lng,
      //     });
      //   }
      //   console.log("polygonPaths", this.polygonPaths);
      // });
    },

    startDrawFence() {
      this.drawFenceStatu = true;
      this.selectDrawFenceTypeShowToggle(false);

      console.log("开始新增围栏");

      if (this.currentFenceType == 1) {
        console.log("this.circleEditor", this.circleEditor);

        this.circleEditor.open();
      } else {
        console.log("this.polyEditor", this.polyEditor);
        this.polyEditor.open();
      }

      // this.show = false;
      //
    },
    endDrawFence() {
      window.location.reload(true);

      // if (this.currentFenceType == 1) {
      //   this.circleEditor.close();
      // } else {
      //   this.polyEditor.close();
      // }
    },

    closePoly() {
      this.show = true;
      this.polyEditor.close();
    },
    reset() {
      this.drawFenceStatu = false;
      this.polyEditor = null;
      this.circleEditor = null;
      this.initPoly();
    },
    async operateFencefn() {
      console.log("保存围栏");

      if (this.currentFenceType == 1) {
        this.circleEditor.close();
      } else {
        this.polyEditor.close();
      }
    },
    lineShowToggle() {
      this.lineShow = !this.lineShow;

      if (this.lineShow) {
        this.carLsit.forEach((car) => {
          this.drawPolyline(car);
        });
      } else {
        this.drawPolyline(null);
      }
    },
  },
};
</script>

<style lang="less" scoped>
#test15 {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.bigdiv {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.bottomdiv {
  height: 150px;
  background-color: #fff;
  position: absolute;
  z-index: 666;
  width: 100%;
  transition: 0.5s;
}

.bottomdivShow {
  bottom: 0px;
}

.bottomdivNone {
  bottom: -150px;
}

#container {
  width: calc(100vw - 270px);
  height: 100vh;
  z-index: 0;
}

.containerdiv {
  width: calc(100vw - 270px);
  height: 100vh;
  position: relative;
}

#index_sheet_left {
  width: 270px;
  height: 100vh;
  padding-top: 30px;
  overflow: hidden;
  background-color: #fff;
}

.el-select {
  width: 100%;
}

.el-radio-group {
  width: 100%;
  display: flex;
}

.el-radio-button {
  flex: 1;
}

::v-deep .el-radio-button__inner {
  width: 100%;
}

.item {
  height: 43px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 1px solid rgb(235, 238, 245);

  .item-spot {
    background-color: #ff4d4f;
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }

  .item-spot1 {
    background-color: #52c41a;
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }

  .item-caozuo {
    color: #1890ff;
  }
}

.item1 {
  height: 43px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  color: #fff;
  background-color: #53aaf3;
  border-bottom: 1px solid rgb(235, 238, 245);

  .item-spot {
    background-color: #ff4d4f;
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }

  .item-spot1 {
    background-color: #52c41a;
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }

  .item-caozuo {
    color: #fff;
  }
}

.bottomdiv-title {
  padding: 10px 24px;
  text-align: center;
  border-bottom: 1px solid rgb(235, 238, 245);
}

.bottomdiv-one {
  span {
    display: inline-block;
    flex: 1;
  }

  margin-bottom: 10px;
  display: flex;
}

::v-deep .el-button--small {
  width: 100px !important;
}
</style>
